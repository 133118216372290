import { RedStripText, RedStripWrapper } from './RedStrip.styles'
import { IRedStrip } from '../../types'

const RedStrip = ({ color, text }: IRedStrip) => {
  return (
    <RedStripWrapper>
      <RedStripText color={color}>
        <div>{text}</div>
      </RedStripText>
      {/*<TriangleSVG*/}
      {/*  version='1.1'*/}
      {/*  xmlns='http://www.w3.org/2000/svg'*/}
      {/*  x='0px'*/}
      {/*  y='0px'*/}
      {/*  viewBox='0 0 13 16'*/}
      {/*>*/}
      {/*  <g>*/}
      {/*    <polygon*/}
      {/*      className='st0'*/}
      {/*      points='13,0 13,16 0,16 11,0'*/}
      {/*      fill={color || '#ed1c24'}*/}
      {/*    />*/}
      {/*  </g>*/}
      {/*</TriangleSVG>*/}
    </RedStripWrapper>
  )
}
export default RedStrip
