import styled from 'styled-components'
import { device } from '../../styles/GlobalVariables'

const heightMobile = 23
const heightDesktop = 23
export const RedStripWrapper = styled.div`
  width: 100%;
  position: absolute;
  text-align: right;
  direction: rtl;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  z-index: 1;
  bottom: 0;
  font-size: 15px;

  ${device.small} {
    min-width: 40px;
    font-size: 16px;
  }
`
export const RedStripText = styled.div<{ color?: string }>`
  max-width: calc(100% - 35px);
  background-color: ${props => props.color || '#ed1c24'};
  mask-image: url('https://media.reshet.tv/image/upload/v1704030544/TriangleB_uwldyl.svg');
  mask-size: cover;
  mask-position: left;
  padding: 2px 4px 2px 5px;
  font-family: 'Narkiss Block Reshet', serif;
  font-weight: bold;
  color: white;
  align-items: center;
  //block-size: fit-content;
  position: relative;
  z-index: 2;

  div {
    transform: scale(0.85);
    transform-origin: center right;
    overflow: visible;
    white-space: nowrap;
  }

  ${device.small} {
    height: ${heightDesktop}px;
    padding: 2px 7px 2px 17px;
    max-width: 100%;

    div {
      transform: unset;
    }
  }
`
export const TriangleSVG = styled.svg`
  z-index: 1;
  height: ${heightMobile}px;
  position: relative;
  right: -2px;
  top: 0;

  ${device.small} {
    height: ${heightDesktop}px;
  }
`
